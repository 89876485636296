<template>
  <div class="w-full mt-5 lg:w-full">
    
    <div v-for="(item, index) in faq" :key="index" class="mb-1">
      <div class="flex flex-wrap items-center px-2 py-2 font-medium bg-white rounded-md cursor-pointer text-gray-1 md:text-center" @click="makeActive(index)" ref="question">
        <p class="flex-1 text-sm font-semibold leading-tight text-gray-1 md:text-left md:text-lg">{{ item.q }}</p>
        <div class="px-2 text-2xl transition duration-200 delay-200 transform text-red-1" :class="[ qIndex === index ? 'rotate-45 ' : '' ]">
          +
        </div>
      </div>

      <collapse-transition>
        <div v-show="qIndex === index ? true : false" class="px-2 py-5 mt-1 text-sm font-medium text-gray-600 transition duration-500 ease-in-out transform bg-white border-b-2 rounded bg-opacity-90 border-green-4">
          {{ item.a }}
        </div>
      </collapse-transition>

    </div>
  </div>
</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition'
export default {
  name: 'Accordion',
  props: {
    faq: {
      type: Array,
      required: true
    },
    focus: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CollapseTransition
  },
  data() {
    return {
      qIndex: 0,
    }
  },
  methods: {
    makeActive(index) {
       index === this.qIndex ? this.qIndex = -1 : this.qIndex = index
       if (this.focus) this.$scrollTo(this.$refs.question[index], 500, {offset: -80})
    }
  }
}
</script>

<style>
/* Border Style */
/* .borderCustom{border: 2px solid #c3a08d;}
.borderCustom:last-child{border: none;} */
</style>