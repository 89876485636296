<template>
  <div>
    <WaBtn />
    <!-- main-banner  -->
    <MainBanner />

    <!-- services  -->
    <div class="py-10">
      <div class="container">
        <div class="flex items-center">
          <div class="h-10 w-0.5 bg-red-1"></div>
          <h2 class="pl-4 text-2xl font-bold text-gray-600">Our Services</h2>
        </div>
        <div class="pt-8 md:flex">
          <div
            v-for="(item, i) in our_services"
            :key="i"
            class="pb-6 md:w-1/2 md:px-1 lg:px-3 md:pb-0"
          >
            <div class="lg:w-3/4 xl:w-2/3">
              <hooper
                :itemsToShow="1"
                :centerMode="true"
                :wheelControl="false"
                class=""
              >
                <slide
                  v-for="(slide, indx) in item.slides"
                  :key="indx"
                  :index="indx"
                  class=""
                >
                  <div class="">
                    <img :src="slide + '.jpg'" alt="" class="w-full" />
                  </div>
                </slide>
                <hooper-navigation slot="hooper-addons"></hooper-navigation>
              </hooper>
            </div>
            <!-- <img src="/images/service-1.jpg" alt="" class="lg:w-2/3" /> -->
            <div class="pt-3">
              <h3 class="text-xl font-semibold text-blue-1">{{ item.h1 }}</h3>
              <p class="pt-2 text-sm text-gray-600">
                {{ item.p }}
              </p>
            </div>
          </div>
          <!-- <div class="md:w-1/2 md:px-1">
            <img src="/images/service-2.jpg" alt="" class="lg:w-2/3" />
            <div class="pt-3">
              <h3 class="text-xl font-semibold text-blue-1">
                Office & Commercial
              </h3>
              <p class="pt-2 text-sm text-gray-600">
                As we know, our property is the largest investment throughout
                our lives. We want work live comfortably in our office and of
                course with added security. We can help you protect your
                valuable things, which matters the most. In addition to that,
                the high efficiency of our window films is able to reduce the
                heat entering your office. Hence, lowering the usage of your air
                conditioning power and utility bills.
              </p>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <!-- service provide to  -->
    <div class="py-10 bg-gray-100">
      <div class="container">
        <h2 class="text-2xl font-bold text-center text-blue-1">
          Our Services Provide To
        </h2>
        <div class="flex flex-wrap mt-8 md:justify-center">
          <div
            v-for="(item, i) in type_service"
            :key="i"
            class="w-1/2 p-1 md:w-1/3 lg:w-1/5"
          >
            <div
              class="transition duration-200 ease-in-out transform bg-white rounded-md hover:scale-105 hover:shadow-lg"
            >
              <img
                :src="item.image"
                :alt="item.alt"
                class="mx-auto rounded-t-md"
              />
              <div class="py-4 text-sm font-semibold text-center text-gray-600">
                <h1 class="leading-tight">{{ item.alt }}</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <h1
            class="w-48 py-2 mx-auto text-center text-white transition duration-200 ease-linear transform rounded-md hover:scale-110 bg-blue-1"
          >
            <a
              href="https://wa.me/60127733462?text=Hi,%20I%20would%20like%20to%20enquiry%20about%20the%20residential,%20office%20and%20commercial%20tinted%20solutions,%20thank%20you"
              ><i class="pr-2 fa-brands fa-whatsapp"></i>Whatsapp Now</a
            >
          </h1>
        </div>
      </div>
    </div>

    <!-- why choose us  -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-choose.jpg')"
    >
      <div class="container">
        <h2 class="text-2xl font-bold text-center text-white">Our Strength</h2>

        <div class="pt-8 md:flex md:flex-wrap lg:justify-center">
          <div
            v-for="(item, i) in choose_us"
            :key="i"
            class="pb-3 md:px-1 md:w-1/2 lg:w-1/3"
          >
            <div
              class="flex items-center justify-center p-5 bg-white rounded-full rounded-br-none bg-opacity-80"
            >
              <div class="w-1/3">
                <img :src="item.image" :alt="item.alt" class="w-16 mx-auto" />
              </div>
              <div class="w-2/3 pl-4 font-semibold text-left">
                <p>{{ item.alt }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Rental Package -->
    <div class="py-8 bg-gray-200">
      <div class="container">
        <h1 class="text-2xl font-bold text-center text-blue-1 lg:text-3xl">
          Our Products
        </h1>

        <!-- packages -->
        <div class="pt-8 md:pt-20 md:flex">
          <div
            v-for="(item, i) in rental"
            :key="i"
            :class="item.scale"
            class="w-4/5 mx-auto md:w-1/3 pb-14"
          >
            <div class="lg:border lg:border-gray-200 lg:rounded-lg">
              <div
                class="relative pb-10 transition duration-200 ease-linear bg-white rounded-lg hover:shadow-md"
              >
                <div class="rounded-t-lg" :class="item.bg">
                  <h1
                    v-html="item.h1"
                    class="py-4 text-2xl font-bold text-center text-white lg:py-6"
                  >
                    {{ item.h1 }}
                  </h1>
                </div>
                <div v-show="item.popular" class="py-3">
                  <img :src="item.popular" alt="" class="w-40 mx-auto" />
                </div>
                <div :class="item.height">
                  <ul
                    class="pt-5 pb-10 space-y-2 text-sm list-disc list-outside lg:pl-12 lg:text-base px-7"
                  >
                    <li v-for="list in item.sublist" :key="list">{{ list }}</li>
                  </ul>
                  <div v-show="item.p1" class="">
                    <img
                      :src="item.p1"
                      alt=""
                      class="w-40 mx-auto md:w-20 lg:w-24"
                    />
                  </div>
                </div>
                <div
                  class="absolute transform -translate-x-1/2 left-1/2 -bottom-5"
                >
                  <p
                    class="w-56 py-2 mx-auto text-xl font-semibold text-center text-white transition duration-200 transform rounded-lg md:w-52 hover:scale-110"
                    :class="item.bg"
                  >
                    <a :href="item.text">Whatsapp Now</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- pdf -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-2xl font-bold text-center text-gray-600">
          e-CATALOGUE
        </h1>

        <div class="pt-8 md:flex md:flex-wrap lg:max-w-5xl lg:mx-auto">
          <div
            v-for="(item, i) in catalog"
            :key="i"
            class="pb-6 md:w-1/3 md:px-2 lg:px-1"
          >
            <h2
              class="mb-2 text-xl font-bold text-center text-gray-600 uppercase"
            >
              {{ item.h1 }}
            </h2>
            <a :href="item.link" target="_blank" class="">
              <img
                :src="item.image"
                data-rel="fh5-light-box-demo"
                data-width="550"
                data-height="350"
                :data-title="item.title"
                class="mx-auto"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- faq -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-faq.jpg')"
    >
      <div class="container xl:py-14">
        <div class="md:w-2/3 xl:w-1/2">
          <div class="flex items-center">
            <div class="h-10 w-0.5 bg-gray-800"></div>
            <h2 class="pl-4 text-2xl font-bold text-gray-800">
              Frequently Asked Questions
            </h2>
          </div>
          <div class="">
            <Accordion :faq="faq" :focus="false" class="pt-4" />
          </div>
        </div>
      </div>
    </div>

    <!-- gallery  -->
    <div class="py-10">
      <div class="container">
        <div class="flex items-center">
          <div class="h-10 w-0.5 bg-red-1"></div>
          <h2 class="pl-4 text-2xl font-bold leading-tight text-gray-600">
            Our Portfolio <br /><span class="text-blue-1">Residential</span>
          </h2>
        </div>

        <div class="flex flex-wrap pt-8 md:justify-center">
          <div
            v-for="image in gallery"
            :key="image"
            class="w-1/2 md:p-1 md:w-1/4 lg:w-1/6"
          >
            <img :src="image" alt="" class="border-2 border-blue-1" />
          </div>
        </div>

        <div class="flex items-center pt-8">
          <div class="h-10 w-0.5 bg-red-1"></div>
          <h2 class="pl-4 text-2xl font-bold leading-tight text-gray-600">
            Our Portfolio <br /><span class="text-blue-1"
              >Office & Commercial</span
            >
          </h2>
        </div>

        <div class="flex flex-wrap pt-8 md:justify-center">
          <div
            v-for="image in gallery_2"
            :key="image"
            class="w-1/2 md:p-1 md:w-1/4 lg:w-1/6"
          >
            <img :src="image" alt="" class="border-2 border-blue-1" />
          </div>
        </div>
      </div>
    </div>

    <!-- awards -->
    <div class="py-10">
      <div class="container">
        <div class="flex items-center">
          <div class="h-10 w-0.5 bg-red-1"></div>
          <h2 class="pl-4 text-2xl font-bold text-gray-600">
            Awards & Recognition
          </h2>
        </div>

        <div
          class="pt-8 md:flex md:flex-wrap md:items-center xl:max-w-5xl xl:mx-auto"
        >
          <div
            v-for="image in awards"
            :key="image"
            class="w-2/3 mx-auto md:w-1/4"
          >
            <img
              :src="image"
              alt=""
              class="transition duration-200 ease-linear transform hover:scale-125"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <div class="md:flex md:items-center">
          <div class="md:w-1/2">
            <img src="/images/shj-auto-1.png" alt="" class="mx-auto w-52" />
          </div>
          <div class="pt-5 md:pt-0 md:w-1/2">
            <div class="flex items-center">
              <div class="h-10 w-0.5 bg-red-1"></div>
              <h2 class="pl-4 text-2xl font-bold text-gray-600">About Us</h2>
            </div>
            <div class="pt-6 space-y-3 text-sm text-gray-600">
              <p>
                <b>SHJ Auto Sdn Bhd</b> supplies, cuts, hot-forms and installs a
                wide range of High-Performance Window Films for automotive and
                architectural applications. We ensure that the quality and
                services are a hallmark of Vort-X.
              </p>
              <p>
                Our expertise includes OEM & After Market, Distribution,
                Automotive Detailing, Cutting & Hot-forming, and Sales &
                Marketing. Backed by a team of industry experts with years of
                experience and a state-of-the-art facility,
                <b>SHJ Auto Sdn Bhd</b> is fully committed to providing the best
                products and customer experience based on the latest technology
                and innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer  -->
    <div
      id="element"
      class="relative z-0 py-12 text-center text-white bg-blue-3 lg:py-20 enquiry-bottom"
    >
      <div
        class="absolute top-0 w-full py-24 bg-center bg-cover"
        style="background-image: url('/images/bg-enquiry.jpg')"
      >
        <div class="py-20 lg:py-12"></div>
      </div>
      <div class="relative z-40 max-w-screen-xl px-4 mx-auto">
        <h2 class="mb-8 text-2xl font-semibold text-white md:text-3xl lg:mb-12">
          Book your Free Consultation <br class="md:hidden" />with us Today!
        </h2>
        <div
          class="w-full p-8 mb-8 bg-white rounded shadow lg:w-3/4 lg:mx-auto lg:mb-12"
        >
          <EnquiryForm />
        </div>
        <div class="lg:flex lg:items-center">
          <div class="lg:w-1/2 lg:pr-4">
            <img
              src="/images/main-logo.png"
              alt=""
              class="mx-auto w-60 md:w-80"
            />
            <div class="pt-3 text-gray-700">
              <h1 class="text-lg font-medium">
                B-2-18, Plaza Damas 3, No.63 Jalan Sri Hartamas 1, Taman Sri Hartamas, 50480 Kuala Lumpur
              </h1>
              <div class="pt-2 font-medium">
                <p>
                  Call Us At
                  <a
                    href="tel:+60362063388"
                    class="transition duration-200 ease-in-out hover:text-red-1"
                    >03-6206 3388</a
                  >
                  |
                  <a
                    href="tel:+60127733462"
                    class="transition duration-200 ease-in-out hover:text-red-1"
                    >012-773 3462</a
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="pt-4 lg:pt-0 lg:w-1/2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.7375300995714!2d101.65551571455077!3d3.1637069976964343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4ba816afc6db%3A0xb52d6b152696b4d9!2sSHJ%20Auto%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1665475853199!5m2!1sen!2smy"
              width="100%"
              height="300"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- Copyright -->
    <div class="py-3 bg-red-1">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Accordion from "@/components/Accordion.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";
import MainBanner from "@/components/MainBanner.vue";
import WaBtn from "@/components/WaBtn.vue";
import "hooper/dist/hooper.css";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";

export default {
  name: "Home",
  components: {
    Accordion,
    EnquiryForm,
    MainBanner,
    WaBtn,
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      info: [
        {
          bgColor: "bg-blue-500",
          p: "IONMax Series",
        },
        {
          bgColor: "bg-blue-600",
          p: "Supreme Series",
        },
        {
          bgColor: "bg-blue-700",
          p: "Pro Series",
        },
      ],
      choose_us: [
        {
          image: "/images/choose-1.png",
          alt: "Increased privacy",
        },
        {
          image: "/images/choose-2.png",
          alt: "Protection against glare",
        },
        {
          image: "/images/choose-3.png",
          alt: "Durability up to 15 years",
        },
        {
          image: "/images/choose-4.png",
          alt: "Protect furniture from UV light",
        },
        {
          image: "/images/choose-5.png",
          alt: "OEM standard of quality control",
        },
      ],
      service: [
        {
          bgColor: "bg-red-1",
          image: "/images/service-1.jpg",
          alt: "IONMax Series",
          sublist: [
            "Anti Bacterial",
            "High-Heat Refection",
            "Triple Protection",
            "10 Years Warranty",
          ],
        },
        {
          bgColor: "bg-gray-800",
          image: "/images/service-2.jpg",
          alt: "Supreme Series",
          sublist: [
            "High-Heat Refection",
            "Variety of Type & Colour",
            "5 Years Warranty",
          ],
        },
        {
          bgColor: "bg-blue-1",
          image: "/images/service-3.jpg",
          alt: "Pro Series",
          sublist: [
            "High-Heat Refection",
            "Variety of Type & Colour",
            "3 Years Warranty",
          ],
        },
      ],
      faq: [
        {
          q: "What is window film?",
          a: "A micro-thin material that allows adequate sunlight with less heat and UV rays. ",
        },
        {
          q: "Will window film obstruct my view?",
          a: "No. Today’s high-quality window films reduce the sun's glare and will have a sharper view through your windows.",
        },
        {
          q: "Will window tinting darken my house and office?",
          a: "Vort-X carries a variety of window films depending on your specific needs. Window tints still allow light to enter your space. It may brighten your home & office even more, as you won’t need to use curtains or blinds anymore.",
        },
        {
          q: "How long will window tint last?",
          a: "Typically window tinting will last approximately 15 years. The exact length of time depends upon the type of film applied, window orientation, type of glass it is applied to, and the particular climate in which it is applied.",
        },
        {
          q: "Do I need a professional to help install my window tints? ",
          a: "Window tinting is a skill that can take years to master. We highly recommend utilizing a trusted professional service like Vort-X for your window tinting needs. ",
        },
      ],
      gallery: [
        "/images/gallery-1.jpg",
        "/images/gallery-2.jpg",
        "/images/gallery-3.jpg",
        "/images/gallery-4.jpg",
        "/images/gallery-5.jpg",
        "/images/gallery-6.jpg",
      ],
      gallery_2: [
        "/images/gallery-7.jpg",
        "/images/gallery-8.jpg",
        "/images/gallery-9.jpg",
        "/images/gallery-10.jpg",
        "/images/gallery-11.jpg",
        "/images/gallery-12.jpg",
      ],
      rental: [
        {
          height: "md:h-60 lg:h-72",
          bg: "bg-1",
          h1: "NANO CERAMIC SERIES",
          sublist: [
            "High-Heat Refection",
            "Variety of Type & Colour",
            "3 Years Warranty",
          ],
          text: "https://wa.me/60127733462?text=Hi,%20I%20would%20like%20to%20enquiry%20about%20the%20Nano%20Ceramic%20Series%20promotion%20package,%20thank%20you",
        },
        {
          scale: "md:transform md:scale-110 md:z-10",
          bg: "bg-2",
          popular: "/images/popular.png",
          h1: "SPUTTER SERIES",
          sublist: [
            "High-Heat Refection",
            "Variety of Type & Colour",
            "5 Years Warranty",
          ],
          text: "https://wa.me/60127733462?text=Hi,%20I%20would%20like%20to%20enquiry%20about%20the%20Sputter%20Series%20promotion%20package,%20thank%20you",
        },
        {
          height: "md:h-60 lg:h-72",
          bg: "bg-3",
          h1: "Negative ION Series",
          sublist: [
            "Anti Bacterial",
            "High-Heat Refection",
            "Triple Protection",
            "10 Years Warranty",
          ],
          p1: "/images/recomend.png",
          text: "https://wa.me/60127733462?text=Hi,%20I%20would%20like%20to%20enquiry%20about%20the%20Negative%20ION%20Series%20promotion%20package,%20thank%20you",
        },
      ],
      awards: [
        "/images/certi-1.jpg",
        "/images/certi-2.jpg",
        "/images/certi-3.jpg",
      ],
      our_services: [
        {
          slides: [
            "/images/service-1",
            "/images/service-2",
            "/images/service-3",
          ],
          h1: "Residential",
          p: "The sun can make the rooms in your home overheat and glare as well as can cause damage to your expensive furniture and fade your wood floors. With Vort-X, you can save on cooling costs and protect your family from harmful UV rays while adding lasting value and beauty to your home.",
        },
        {
          slides: [
            "/images/service-4",
            "/images/service-5",
            "/images/service-6",
          ],
          h1: "Office & Commercial",
          p: "As we know, our property is the largest investment throughout our lives. We want work live comfortably in our office and of course with added security. We can help you protect your valuable things, which matters the most. In addition to that, the high efficiency of our window films is able to reduce the heat entering your office. Hence, lowering the usage of your air conditioning power and utility bills.",
        },
      ],
      catalog: [
        {
          h1: "NANO CERAMIC SERIES",
          link: "https://online.anyflip.com/vldcc/enpr/index.html",
          image: "https://online.anyflip.com/vldcc/enpr/files/shot.jpg",
          title: "Vort-X Pro Series Catalog",
        },
        {
          h1: "SPUTTER SERIES",
          link: "https://online.anyflip.com/vldcc/qlcx/index.html",
          image: "https://online.anyflip.com/vldcc/qlcx/files/shot.jpg",
          title: "Vort-X Supreme Series Catalog",
        },
        {
          h1: "Negative Ion Series",
          link: "https://online.anyflip.com/vldcc/ecci/index.html",
          image: "https://online.anyflip.com/vldcc/ecci/files/shot.jpg",
          title: "Vort-X Negative Ion Series Catalog",
        },
      ],
      type_service: [
        { image: "/images/provide-1.jpg", alt: "Residential" },
        { image: "/images/provide-2.jpg", alt: "Office & Commercial" },
        { image: "/images/provide-3.jpg", alt: "Mall & Retail" },
        { image: "/images/provide-4.jpg", alt: "Hotel" },
        { image: "/images/provide-5.jpg", alt: "Tuition Centre" },
      ],
    };
  },
};
</script>

<style>
.bg-1 {
  background-image: url('/public/images/bg-1.jpg');
  /* background-image: url("/images/bg-1.jpg"); */
  background-position: center;
  background-size: cover;
}
.bg-2 {
  background-image: url('/public/images/bg-2.jpg');
  /* background-image: url("/images/bg-2.jpg"); */
  background-position: center;
  background-size: cover;
}
.bg-3 {
  background-image: url('/public/images/bg-3.jpg');
  /* background-image: url("/images/bg-3.jpg"); */
  background-position: center;
  background-size: cover;
}
.hooper-next,
.hooper-prev {
  background-color: #fff;
  opacity: 0.8;
  top: 50%;
}
.hooper {
  height: auto;
}
/* @media (min-width: 1024px) {
  .hooper {
    height: 400px;
  }
} */
</style>
