<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/bg-main.jpg')"
  >
    <div class="container py-20 md:py-32">
      <div class="md:flex md:items-center">
        <div class="md:w-1/2">
          <img src="/images/main-logo.png" alt="" class="w-40 pb-3 md:w-56" />
          <div class="text-white">
            <h1 class="pb-2 text-3xl font-bold">
              Malaysia Top Choice Residential, Office & Commercial <span class="text-blue-2">Tinted Solutions</span>
            </h1>
            <h2 class="text-lg font-bold lg:text-xl">
              Experience | Quality | Security
            </h2>
          </div>
          <div v-if="!hideEnquiry" class="flex pt-4 space-x-1 text-center">
            <h2
              class="w-1/2 py-2 font-semibold text-white transition duration-200 ease-in-out border border-white rounded hover:bg-white hover:text-gray-700 md:w-40"
            >
              <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }"  
                >Enquire Us</a
              >
            </h2>
            <h2
              class="w-1/2 py-2 font-semibold text-white transition duration-200 ease-in-out border border-white rounded hover:bg-white hover:text-gray-700 md:w-40"
            >
              <a href="tel:+60127733462"
                >Call Us</a
              >
            </h2>
          </div>
        </div>
        <div class="pt-8 md:w-1/2">
          <img src="/images/building-1.png" alt="" class="mx-auto" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
